import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Article from "../components/Article"
import { Row, Col } from "react-bootstrap"

const blog = () => {
  return (
    <div>
      <SEO title="Articles" />
      <Layout>
        <h2>Articles</h2>
        <Row>
          <Col>
            <Article
              articleTitle="Five Valuable Non-Tech Skills for Software Engineers"
              imageSrc="https://miro.medium.com/max/1400/0*SfU86bpK3u9M9HQe.jpeg"
              description="Key competencies to be successful in a tech career (Published in The Startup)"
              url="https://medium.com/swlh/five-valuable-non-tech-skills-for-software-engineers-64ac2d78b0bf?source=post_settings_page-----64ac2d78b0bf----------------------"
              whitespace={true}
            />
          </Col>
          <Col>
            <Article
              articleTitle="The Fourth Industrial Revolution and Its Effect on the Creation of New Businesses"
              imageSrc="https://miro.medium.com/max/6706/1*xPN1TMIK_kHoVBcQLKErYA.jpeg"
              description="An analysis of the history, present, and future of creating enterprises through the lens of the four industrial revolutions"
              url="https://medium.com/swlh/the-fourth-industrial-revolution-and-its-effect-on-the-creation-of-new-businesses-19c4286be3fc"
            />
          </Col>
          <Col></Col>
        </Row>
      </Layout>
    </div>
  )
}

export default blog

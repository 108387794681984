import React from "react"
import { Card } from "react-bootstrap"
import styled from "styled-components"
import { FaChevronRight } from "react-icons/fa"
import { number } from "prop-types"

interface IArticle {
  articleTitle: string
  imageSrc: string
  description: string
  url: string
  whitespace?: boolean
}

const StyledCard = styled(Card)`
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 20rem;
  height: 32rem;

  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 5px 7.5px 3.5px #c7c7c7;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .card-text {
    text-align: right;
    color: #555;
  }

  .card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
  }

  @media screen and (max-width: 500px) {
    box-shadow: 5px 7.5px 3.5px #c7c7c7;
    margin-bottom: 2em;
  }
`

const Article = ({
  articleTitle,
  imageSrc,
  description,
  url,
  whitespace = false,
}: IArticle) => (
  <StyledCard>
    <a href={url}>
      <Card.Img variant="top" src={imageSrc} />
      <Card.Body>
        <Card.Title>{articleTitle}</Card.Title>
        <Card.Subtitle>{description}</Card.Subtitle>
        <br />
        {whitespace && (
          <>
            <br />
            <br />
          </>
        )}
        <Card.Text>
          Read the full article on Medium <FaChevronRight />
        </Card.Text>
      </Card.Body>
    </a>
  </StyledCard>
)

export default Article
